<script>
import Layout from "../../../../layouts/main";
import PDFViewer from 'pdf-viewer-vue'


export default {
  page: {
    meta: [
      {
        name: "description"
      },
    ],
  },
  components: {
    Layout,
    PDFViewer
  },
  data() {
    return {
      pdf_source:"",
      transactions: [],
      files: [],
      transaction: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchModel: "",
      searchMode: false,
    };
  },
  methods: {
    get(page) {
      console.log(page);
      this.http
        .post("transactions/details/pagination", {
          limit: this.limit,
          page: page,
          id: parseInt(this.$route.params.id),
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          res.data.sort((t1)=>t1.type=='debit'?-1:1)
          this.transactions = res.data;
          this.files = res.files;
          this.transaction = res.data[0];
        });
    },
    printDocument(file){
      if(location.href.includes("sayedmustafa.com")){
        let file_name = file.split("/")
        file_name = file_name[file_name.length-1]
        this.http.download("pos-chart-of-accounts/tree/download",file_name , {
          file
        })
      }
      else
      this.http
          .post("transactions/print-document", {
            file
          })
          .then((res) => {
            if(res.status){
              this.popup.alert({
                title: "popups.done",
                msg: "popups.success",
              });
            }
          })
    }
  },
  computed:{
    totalDebit(){
      return this.transactions
          .filter(t=>t.type=="debit")
          .reduce((sum,t)=>sum+parseFloat(t.value),0)
    },
    totalCredit(){
      return this.transactions
          .filter(t=>t.type=="credit")
          .reduce((sum,t)=>sum+parseFloat(t.value),0)
    }
  },
  created() {
    this.get(1);
  },
};
</script>

<template>
  <Layout>
    <div class="row" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <div class="row align-items-center">
        <div class="d-flex align-items-center mb-4">
          <router-link :to="`/journal_entries`">
            <h4 class="m-0">
              {{ $t("journal.title") }}
            </h4>
          </router-link>
          <div class="mx-2">\</div>
          <div>#{{ transaction?.transaction_id }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card" style="width:fit-content">
          <div class="card-body">
            <div class="row">
              <div class="col-6"></div>
              <div
                class="col-6"
                style="display: flex; justify-content: flex-end"
              >
                <router-link to="/add_journal">
                  <button type="button" class="btn btn-light float-end mb-4">
                    <span
                      class="bx bxs-plus-circle float-end fa-2x"
                      style="color: #2a3042 !important"
                    ></span>
                  </button>
                </router-link>
              </div>
            </div>

            <!-- Start  table -->
            <table
              class="table table-centered table-nowrap table-striped table-hover align-middle"
            >
              <thead>
                <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">#</th>
                  <th scope="col">{{ $t("journal.date") }}</th>
                  <th scope="col">{{ $t("journal.account_no") }}</th>
                  <th scope="col">Account</th>
                  <th scope="col">{{ $t("journal.tr_descr") }}</th>
                  <th scope="col">{{ $t("journal.debit") }}</th>
                  <th scope="col">{{ $t("journal.credit") }}</th>

                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(transaction, index) in transactions"
                  :key="transaction"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ transaction?.created?.split("T")[0] }},
                    {{ transaction?.created?.split("T")[1]?.split(".")[0] }}
                  </td>
                  <td>{{ transaction?.code }}</td>
                  <td>{{$i18n.locale == "ar" ? transaction?.name :transaction?.name_en  }}</td>
                  <td>
                    {{
                      $i18n.locale == "ar"
                        ? transaction?.descr
                          ? transaction?.descr
                          : transaction?.descr
                        : transaction?.descr_en
                        ? transaction?.descr_en
                        : transaction?.descr
                    }}
                  </td>
                  <td>
                    {{
                      transaction?.type == "debit"
                          ? parseFloat(transaction?.value).toLocaleString()
                          : ""
                    }}
                    <small :title="$t('currencies.rate')+' : '+transaction?.currency_rate" v-if="transaction.currency_id">
                      <br>
                      {{ transaction?.type == "debit"
                        ? " ("+parseFloat(transaction?.value_in_other_currency).toLocaleString() +
                        (transaction?.symbol
                            ? " "+transaction?.symbol+")"
                            : transaction?.short_name ? " ("+transaction?.short_name +")": "") :""
                      }}
                    </small>
                  </td>
                  <td>
                    {{
                      transaction?.type == "credit"
                        ? parseFloat(transaction?.value).toLocaleString()
                        : ""
                    }}
                    <small :title="$t('currencies.rate')+' : '+transaction?.currency_rate" v-if="transaction.currency_id">
                      <br>
                      {{ transaction?.type == "credit"
                        ? " ("+parseFloat(transaction?.value_in_other_currency).toLocaleString() +
                        (transaction?.symbol
                      ? " "+transaction?.symbol+")"
                      : transaction?.short_name ? " ("+transaction?.short_name +")": "") :""
                      }}
                    </small>
                  </td>
                </tr>
              <tr>
                <td colspan="5"></td>
                <td>{{totalDebit.toLocaleString()}}</td>
                <td>{{totalCredit.toLocaleString()}}</td></tr>
              </tbody>
            </table>
            <!-- end  table -->

            <!--  Start Apps  Pagination     -->
            <ul
              class="pagination pagination-rounded justify-content-center mb-2"
            >
              <li class="page-item" :class="{ disabled: page == 1 }">
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(page - 1)"
                  aria-label="Previous"
                >
                  <i class="mdi mdi-chevron-left"></i>
                </a>
              </li>
              <li
                class="page-item"
                :class="{ active: p == page }"
                v-for="p in tot_pages"
                :key="p"
              >
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(p)"
                  >{{ p }}</a
                >
              </li>

              <li class="page-item" :class="{ disabled: page == tot_pages }">
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(page + 1)"
                  aria-label="Next"
                >
                  <i class="mdi mdi-chevron-right"></i>
                </a>
              </li>
            </ul>

            <br><br>

            <div v-if="files.length > 0" class="table-responsive">
              <h5>{{$t('journal.documents')}}</h5>
              <table class="table table-striped table-centered table-nowrap align-middle">
                <thead>
                <tr class="text-light" style="background-color: #2a3042 !important">
                  <td>#</td>
                  <td>{{ $t("purchases.name") }}</td>
                  <td>{{ $t("purchases.attachment") }}</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(file, index) in files" :key="file">
                  <td>{{ index + 1 }}</td>
                  <td>{{ file?.name }}</td>
                  <td>
                    <a class="btn btn-primary" target="_blank" :href="`${$store.state.filesUrl}${file?.file?.split(
    '/public/'
  )[1]
    }`">{{ $t("emp_files.download") }}</a>
                    <a v-if="file?.file" class="ms-1 btn btn-success" data-bs-toggle="modal" data-bs-target="#pdf-view" @click="pdf_source = $store.state.filesUrl+file.file?.split('public/')[1]">
                      {{ $t("popups.preview") }}
                    </a>
                    <a class="btn btn-success" @click="printDocument(file?.file)">
                      {{ $t("popups.print") }}
                    </a>

                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <!-- End  Apps  Pagination     -->
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="pdf-view" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
         :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
      <div class="modal-dialog modal-lg" style="max-width: 1000px;height: 93vh;">
        <div class="modal-content" style="height: 100% !important;">
          <div class="modal-header">
            <h5 class="modal-title invisible" id="exampleModalLabel">
              PDV Viewer
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="margin: 0"></button>
          </div>

          <div class="modal-body">
            <PDFViewer
                :source="pdf_source"
                style="height: 100%; width: 100%"
            />
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<style scoped>
td,
th,
tr {
  padding: 1rem;
}
</style>
